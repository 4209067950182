import { NavLink, useLocation } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { BsShop } from "react-icons/bs";
import { useEffect, useState } from "react";
import { IoIosLogOut } from "react-icons/io";

import "./side-bar.css";
import { IoSettingsOutline } from "react-icons/io5";

export default function Sidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    // Automatically open the dropdown of the active nav link
    if (location.pathname.startsWith("/mall/profile")) {
      setOpenDropdown("profile");
    } else if (
      location.pathname.startsWith("/mall/store-list") ||
      location.pathname.startsWith("/mall/store-requests")
    ) {
      setOpenDropdown("store");
    } else {
      setOpenDropdown(null);
    }
  }, [location.pathname]);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
      <div className="w-full xl:w-64 h-[100vh] xl:h-[95vh]  bg-[#C19A6B] rounded-[0.7rem] hide-scrollbar overflow-y-auto">
        {/* shop name */}
        <div className=" px-4 py-4">
          <div className="flex gap-2 items-center mb-4">
            <div>
              <img
                className="h-14 w-14 lg:h-16 lg:w-16"
                src="/assets/images/shop-profile.svg"
                alt=""
              />
            </div>
            <div>
              <div className="flex items-center gap-2">
                <div className="text-white font-bold text-sm lg:text-base  tracking-wide">
                  Shop Name
                </div>
              </div>
            </div>
          </div>
          {/* hr */}
          <hr
            className="h-[0.1rem]"
            style={{
              borderRadius: "50%",
            }}
          />
        </div>

        <div className="flex flex-col gap-1 lg:gap-2 px-4">
          {/* dashboard  */}
          <NavLink
            to="/mall/dashboard"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <LuLayoutDashboard className="w-4 h-4" />
            Dashboard
          </NavLink>

          {/* Store section  */}
          <div className="flex relative  flex-col ">
            <NavLink
              onClick={() => toggleDropdown("store")}
              className={`${
                location.pathname.startsWith("/mall/store-list") ||
                location.pathname.startsWith("/mall/store-requests")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <BsShop className="w-4 h-4" />
              Stores
              {openDropdown === "store" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Store dropdown  */}
            {openDropdown === "store" && (
              <>
                <NavLink
                  to="/mall/store-list"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Store List
                </NavLink>
                <NavLink
                  to="/mall/store-requests"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Store Requests
                </NavLink>
              </>
            )}
          </div>

          {/* profile section  */}
          <div className="flex relative  flex-col ">
            <NavLink
              onClick={() => toggleDropdown("profile")}
              className={`${
                location.pathname.startsWith("/mall/profile")
                  ? "activeLink"
                  : "navLink"
              }`}
            >
              <IoSettingsOutline className="w-4 h-4" />
              Account & Settings
              {openDropdown === "profile" ? (
                <RiArrowDropUpLine className="!w-6 !h-6 absolute right-1" />
              ) : (
                <RiArrowDropDownLine className="!w-6 !h-6 absolute right-1" />
              )}
            </NavLink>
            {/* Store dropdown  */}
            {openDropdown === "profile" && (
              <>
                <NavLink
                  to="/mall/profile"
                  className={({ isActive }) =>
                    isActive ? "childActiveLink" : "childLink"
                  }
                >
                  Profile
                </NavLink>
              </>
            )}
          </div>

          {/* logout  */}
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "activeLink" : "navLink")}
          >
            <IoIosLogOut className="w-4 h-4" />
            Logout
          </NavLink>
        </div>
      </div>
    </>
  );
}
