import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearMessage, mallDetailsRegistration } from "../../store/MallSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function BankVerificationPage() {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.mall);

  const navigate = useNavigate();
  const [data, setData] = useState({});

  // const [selectedBank, setSelectedBank] = useState("");
  // const [bankOpen, setBankOpen] = useState(false);

  const [imgFileOne, setImgFileOne] = useState(null);
  const [imgFileTwo, setImgFileTwo] = useState(null);
  const [imgFileThree, setImgFileThree] = useState(null);
  const [chequeImgFile, setChequeImgFile] = useState(null);

  const [verificationImageOne, setVerificationImageOne] = useState(null);
  const [verificationImageTwo, setVerificationImageTwo] = useState(null);
  const [verificationImageThree, setVerificationImageThree] = useState(null);
  const [chequeCopy, setChequeCopy] = useState(null);

  // const handleBankSelection = (value) => {
  //   setSelectedBank(value);
  //   setData({
  //     ...data,
  //     bank: value,
  //     verificationImageOne: verificationImageOne,
  //     verificationImageTwo: verificationImageTwo,
  //     verificationImageThree: verificationImageThree,
  //     chequeCopy: chequeCopy,
  //   });
  //   setBankOpen(false);
  // };

  // const toggleBank = () => {
  //   setBankOpen(!bankOpen);
  // };

  const handleVerificationImageOneUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileOne(file);
      const imageUrl = URL.createObjectURL(file);
      setVerificationImageOne(imageUrl);
    }
  };

  const handleVerificationImageTwoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileTwo(file);
      const imageUrl = URL.createObjectURL(file);
      setVerificationImageTwo(imageUrl);
    }
  };

  const handleVerificationImageThreeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImgFileThree(file);
      const imageUrl = URL.createObjectURL(file);
      setVerificationImageThree(imageUrl);
    }
  };

  const handleChequeCopyUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setChequeImgFile(file);
      const imageUrl = URL.createObjectURL(file);
      setChequeCopy(imageUrl);
    }
  };

  const handleVerificationImageOneDelete = () => {
    setVerificationImageOne(null);
    setImgFileOne(null);
  };

  const handleVerificationImageTwoDelete = () => {
    setVerificationImageTwo(null);
    setImgFileTwo(null);
  };

  const handleVerificationImageThreeDelete = () => {
    setVerificationImageThree(null);
    setImgFileThree(null);
  };

  const handleChequeDelete = () => {
    setChequeCopy(null);
  };

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      bank_account_title,
      bank_account_name,
      // bank_branch_name,
      Iban,
      bank_account_number,
      bank_branch_code,
    } = data;

    if (
      bank_account_title === "" ||
      bank_account_name === "" ||
      Iban === "" ||
      bank_account_number === "" ||
      bank_branch_code === "" ||
      imgFileOne === null ||
      imgFileTwo === null ||
      imgFileThree === null ||
      chequeImgFile === null
    ) {
      toast.error("Please fill All Fields ...");
    } else {
      const formData = new FormData();

      formData.append("bank_account_title", data.bank_account_title);
      formData.append("bank_account_name", data.bank_account_name);
      formData.append("bank_branch_name", data.bank_branch_name);
      formData.append("Iban", data.Iban);
      formData.append("bank_account_number", data.bank_account_number);
      formData.append("bank_branch_code", data.bank_branch_code);
      formData.append("user_id", localStorage.getItem("mall_ID"));
      formData.append("mall_legal_document1", imgFileOne);
      formData.append("mall_legal_document2", imgFileTwo);
      formData.append("mall_legal_document3", imgFileThree);
      formData.append("cheque_copy_image", chequeImgFile);

      dispatch(mallDetailsRegistration(formData));
    }

    // navigate("/mall/creation-complete");
  };

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        navigate("/mall/creation-complete");
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }
  }, [loading, message, error, dispatch, navigate]);

  return (
    <div className="w-full">
      <div className="flex justify-center w-full mt-14">
        <div className="flex flex-col gap-4 md:gap-16">
          <div className="flex justify-center">
            <img
              className="w-[300px] md:w-[372px]"
              src="/assets/icons/progress-3.svg"
              alt=""
            />
          </div>
          <div className="p-4 flex gap-4 md:gap-16">
            <div className="flex flex-col gap-4">
              <span className="font-semibold text-sm md:text-base">
                Please complete the form below to start your own online business
                store!
              </span>

              <span className="font-bold text-lg md:text-2xl flex flex-col mt-3">
                <span>Mall Verification & Bank Account Verification</span>
              </span>

              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 w-full mt-2"
              >
                <div className="font-bold text-base md:text-xl">
                  Mall Verification
                </div>

                <div className="flex flex-col gap-3">
                  <span className="font-medium text-xs">
                    Mall Verification Documents
                  </span>
                  <p className="text-xs text-[#18120F] ">
                    Upload any documents that might help us in verifying your
                    mall(legal documents)
                  </p>
                  {/* verification images  */}

                  {/* image 1  */}
                  <div className="w-full h-44 md:h-72">
                    <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                      <input
                        id="imageOne"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleVerificationImageOneUpload}
                      />
                      {verificationImageOne ? (
                        <div className="relative w-full h-full">
                          <img
                            src={verificationImageOne}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded"
                          />
                          <div className="absolute top-0 right-0 flex gap-2 p-2">
                            <div
                              className="bg-[#C19A6B] text-white p-2 rounded-md"
                              onClick={handleVerificationImageOneDelete}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-[0.75rem] font-[400]">
                            Drag and drop file
                          </span>
                          <span className="text-[0.75rem] font-[400]">or</span>
                          <div
                            className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                            onClick={() =>
                              document.getElementById("imageOne").click()
                            }
                          >
                            Browse
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* image 2  */}
                  <div className="w-full h-44 md:h-72">
                    <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                      <input
                        id="imageTwo"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleVerificationImageTwoUpload}
                      />
                      {verificationImageTwo ? (
                        <div className="relative w-full h-full">
                          <img
                            src={verificationImageTwo}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded"
                          />
                          <div className="absolute top-0 right-0 flex gap-2 p-2">
                            <div
                              className="bg-[#C19A6B] text-white p-2 rounded-md"
                              onClick={handleVerificationImageTwoDelete}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-[0.75rem] font-[400]">
                            Drag and drop file
                          </span>
                          <span className="text-[0.75rem] font-[400]">or</span>
                          <div
                            className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                            onClick={() =>
                              document.getElementById("imageTwo").click()
                            }
                          >
                            Browse
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* image 3  */}
                  <div className="w-full h-44 md:h-72">
                    <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                      <input
                        id="imageThree"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleVerificationImageThreeUpload}
                      />
                      {verificationImageThree ? (
                        <div className="relative w-full h-full">
                          <img
                            src={verificationImageThree}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded"
                          />
                          <div className="absolute top-0 right-0 flex gap-2 p-2">
                            <div
                              className="bg-[#C19A6B] text-white p-2 rounded-md"
                              onClick={handleVerificationImageThreeDelete}
                            >
                              Delete
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <span className="text-[0.75rem] font-[400]">
                            Drag and drop file
                          </span>
                          <span className="text-[0.75rem] font-[400]">or</span>
                          <div
                            className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                            onClick={() =>
                              document.getElementById("imageThree").click()
                            }
                          >
                            Browse
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* bank verification  */}
                <div className="font-bold text-lg md:text-xl">
                  Bank Verification
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Account Title
                  </label>
                  <input
                    type="text"
                    name="bank_account_title"
                    placeholder="Enter Your Account Title"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Account Number
                  </label>
                  <input
                    type="text"
                    name="bank_account_number"
                    placeholder="Enter Your Account Number"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="" className="font-semibold text-xs">
                      Bank Name
                    </label>
                  </div>

                  <input
                    type="text"
                    name="bank_account_name"
                    placeholder="Enter Bank Name"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Branch Name
                  </label>
                  <input
                    type="text"
                    name="bank_branch_name"
                    placeholder="Enter Your Branch Name"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Branch Code
                  </label>
                  <input
                    type="text"
                    name="bank_branch_code"
                    placeholder="Enter Your Branch Code"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    IBAN
                  </label>
                  <input
                    type="text"
                    name="Iban"
                    placeholder="Enter Your IBAN"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    onChange={updateData}
                  />
                </div>

                {/* cheque copy image  */}
                <div className="w-full h-44 md:h-72">
                  <h2 className="text-xs font-semibold ">Cheque Copy</h2>
                  <div className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                    <input
                      id="chequeFileInput"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleChequeCopyUpload}
                    />
                    {chequeCopy ? (
                      <div className="relative w-full h-full">
                        <img
                          src={chequeCopy}
                          alt="Uploaded"
                          className="w-full h-full object-cover rounded"
                        />
                        <div className="absolute top-0 right-0 flex gap-2 p-2">
                          <div
                            className="bg-[#C19A6B] text-white p-2 rounded-md"
                            onClick={handleChequeDelete}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-[0.75rem] font-[400]">
                          Drag and drop file
                        </span>
                        <span className="text-[0.75rem] font-[400]">or</span>
                        <div
                          className="bg-[#C19A6B] px-7 py-[0.63rem] text-[0.75rem] font-semibold rounded-md text-white text-center"
                          onClick={() =>
                            document.getElementById("chequeFileInput").click()
                          }
                        >
                          Browse
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="bg-[#C19A6B] py-3 md:py-[14px] px-5 md:px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
                >
                  {!loading ? "Complete" : "Laoding ..."}
                </button>
              </form>
            </div>
            <div className="hidden lg:block">
              <img src="/assets/icons/bank-verify.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
