import ChatPage from "../pages/Chat-Pages/ChatPage";

const chatRoutes = [
  {
    path: "/mall/chat",

    children: [
      {
        index: true,
        element: <ChatPage />,
      },
    ],
  },
];

export default chatRoutes;
