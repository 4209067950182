import { Link } from "react-router-dom";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeList } from "../../store/MallSlice";

export default function StoreList() {
  const { data, loading } = useSelector((state) => state.mall);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeList());
  }, [dispatch]);
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Store List",
            path: "/mall/store-list",
          },
        ]}
      />

      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full p-5 md:p-3 pl-10 md:pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search Stores..."
          />
        </div>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="text-left w-[25%]">STORE</span>

              <span className="text-left w-[25%]">TOTAL PRODUCTS</span>

              <span className="  text-left w-[25%]">OWNER NAME</span>

              <span className=" text-left w-[25%] ">ACTION</span>
            </div>

            {/* Table Data Row */}

            {loading ? (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            ) : data?.data?.data?.length > 0 ? (
              data?.data?.data?.map((store) => (
                <div
                  // key={item.id}
                  className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                >
                  <div className="flex  items-center gap-4 w-[25%] text-left">
                    <span className="">
                      <img
                        src={store.shop_picture}
                        alt=""
                        className="w-7 h-7 md:w-10 md:h-10 rounded-lg"
                      />
                    </span>
                    <span className="font-semibold text-[#18120F]  ">
                      {store.store_name}
                    </span>
                  </div>
                  <span className="w-[25%] text-left">
                    {" "}
                    {store.products}
                  </span>

                  <span className="w-[25%] text-left ">
                    {store.name}
                  </span>

                  <siv className="w-[25%] text-left">
                    <Link to="">
                      <img
                        src="/assets/icons/view.svg"
                        alt=""
                        className="w-7 h-7 md:w-9 md:h-9"
                      />
                    </Link>
                  </siv>
                </div>
              ))
            ) : (
              <div className="text-center font-bold tracking-wide">
                No Store Found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
