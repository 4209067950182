import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptStore, clearMessage } from "../../store/MallSlice";

export default function RequestModal({ mallId}) {
  const dispatch = useDispatch();
  
  const {error, message,storeData  } = useSelector(
    (state) => state.mall
  );

  useEffect(() => {
    if (message){
      document.getElementById("my_modal_3").close();
    }
    if (error){
      document.getElementById("my_modal_3").close();
    }
    dispatch(clearMessage())
  }, [message, error, dispatch])


  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
        {/* user image and name  */}
        <div className="flex items-center gap-2">
          <span>
            <img
              src={storeData?.shop_picture}
              alt=""
              className="w-10 h-10 md:w-14 md:h-14 rounded-full"
            />
          </span>
          <span className="flex flex-col">
            <span className="font-semibold text-sm md:text-base">
              User Name
            </span>
            <span className="font-semibold text-xs text-[#6B6B6B]">
              {storeData?.user_name}
            </span>
          </span>
        </div>

        {/* user details  */}
        <div className="w-full flex justify-between items-start gap-16">
          {/* 1st  */}
          <div className="w-full flex flex-col">
            {[
              { label: "Full Name", value: storeData?.user_name },
              { label: "E-mail", value: storeData?.email },
              { label: "Store Name", value: "@" + storeData?.store_name },
              { label: "Store Type", value: storeData?.store_type },
              { label: "Style Type", value: storeData?.style_type },
            ].map((detail) => (
              <div
                key={detail.label}
                className="w-full flex justify-between border-b border-[#EBF0ED] py-2"
              >
                <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                  {detail.label}:
                </span>
                <span className="font-semibold text-xs">{detail.value}</span>
              </div>
            ))}
          </div>

          {/* 2nd */}
          <div className="w-full flex flex-col">
            {[
              { label: "Location", value: `${storeData?.country}, ${storeData?.city}` },
              { label: "Postal Code", value: storeData?.postal_code },
              { label: "Address", value: storeData?.address },
            ].map((detail) => (
              <div
                key={detail.label}
                className="w-full flex justify-between border-b border-[#EBF0ED] py-2"
              >
                <span className="font-semibold text-xs text-[#6B6B6B] uppercase">
                  {detail.label}:
                </span>
                <span className="font-semibold text-xs">{detail.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* buttons */}
      <div className="flex justify-between items-center">
        <button className="border border-[#C19A6B] text-[#C19A6B] py-[7px] md:py-[10px] px-5 md:px-9 text-xs md:text-sm font-semibold rounded-lg">
          Visit Store
        </button>

        <div className="flex items-center gap-3">
          <button
            className="border border-[#EBF0ED] text-[#828282] bg-[#FAFAFA] py-[5px] md:py-[10px] px-5 md:px-9 text-xs md:text-sm font-semibold rounded-lg"
            onClick={() => dispatch(acceptStore({status:"reject", id:mallId}))}
          >
            Reject
          </button>
          <button
            className="bg-[#C19A6B] py-[5px] md:py-[10px] px-5 md:px-9 text-white text-sm font-semibold rounded-lg"
            onClick={() => dispatch(acceptStore({status:"approve", id:mallId}))}
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  );
}
