import StoreRequests from "../../components/Store/StoreRequests";
import MallDashboardLayout from "../../layout/MallDashboardLayout";

export default function StoreRequestsPage() {
  return (
    <MallDashboardLayout>
      <StoreRequests />
    </MallDashboardLayout>
  );
}
