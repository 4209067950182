export default function CreationCompletePage() {
  return (
    <div className="w-full">
      <div className="flex justify-center w-full mt-14">
        <div className="flex flex-col gap-16">
          <div className=" flex justify-center">
            <img
              className="w-[300px] md:w-[372px]"
              src="/assets/icons/progress-4.svg"
              alt=""
            />
          </div>
          <div className="lg:px-8 xl:px-0 p-4 flex  gap-16">
            <div className="w-full lg:w-[47%] flex flex-col gap-4">
              <span className="font-bold text-center lg:text-start text-xl md:text-[34px]">
                Congratulations! Your request have been sent
              </span>

              <span className="font-medium text-lg md:text-2xl text-center lg:text-start">
                You will soon receive an email regarding your mall registration.
              </span>
            </div>

            <div className="hidden lg:block">
              <img src="/assets/icons/creation-complete.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
