import React from "react";
import BreadCrums from "../../Common/BreadCrums";
import Loader from "../../Common/Loader";

export default function RegisteredProducts({ regProduct, loading }) {
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Mall Dashboard",
            path: "/mall/dashboard",
          },
          {
            name: "Products Registered",
            path: "/mall/dashboard/registered-products",
          },
        ]}
      />

      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full p-2 md:p-3 pl-10 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search Products..."
          />
        </div>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="text-left w-[20%]">Product</span>

              <span className="ext-left w-[20%]">Stock</span>

              <span className="ext-left w-[20%]">Vendor</span>

              <span className="ext-left w-[20%]">Category</span>
              <span className="ext-left w-[20%]">Price</span>
            </div>

            {/* Table Data Row */}
            {loading ? (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            ) : regProduct && Object.values(regProduct).length > 0 ? (
              Object.values(regProduct).map((storeProducts, storeIndex) => {
                return storeProducts.map((product) => (
                  <div
                    key={product.id} // Make sure to provide a unique key for each product
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="flex items-center gap-4 text-left w-[20%]">
                      <img
                        src={product.image} // Use product image instead of a static image
                        alt={product.name} // Provide a meaningful alt text
                        className="w-7 h-7 md:w-10 md:h-10 rounded-lg"
                      />
                      <span className="font-semibold text-[#18120F]">
                        {product.name}
                      </span>
                    </div>
                    <span className="text-left w-[20%]">
                      {product.quantity}
                    </span>
                    <span className="text-left w-[20%]">
                      {product.user_name}
                    </span>
                    <span className="text-left w-[20%]">
                      {product.category_name}
                    </span>
                    <span className="text-left w-[20%]">${product.price}</span>
                  </div>
                ));
              })
            ) : (
              <div>No products available</div>
            )}

            {/* <div
              // key={item.id}
              className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
            >
              <div className="flex  items-center gap-4 ext-left w-[20%] ">
                <img
                  src="/assets/images/living-room.png"
                  alt=""
                  className="w-7 h-7 md:w-10 md:h-10 rounded-lg"
                />

                <span className=" font-semibold text-[#18120F]  ">Name</span>
              </div>
              <span className="ext-left w-[20%]">12</span>

              <span className="ext-left w-[20%]">Hadeed</span>
              <span className="ext-left w-[20%]">Electronics</span>

              <span className="ext-left w-[20%]">$431</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
