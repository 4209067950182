import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  citiesByStates,
  clearMessage,
  countriesList,
  mallAddressRegistration,
  statesByCountry,
} from "../../store/MallSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function AddressCreation() {
  const dispatch = useDispatch();

  const { loading, message, error, country_list, states_list, cities_list } =
    useSelector((state) => state.mall);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [countryOpen, setCountryOpen] = useState(false);

  const [selectedState, setSelectedState] = useState("");
  const [stateOpen, setStateOpen] = useState(false);

  const [selectedCity, setSelectedCity] = useState("");
  const [cityOpen, setCityOpen] = useState(false);

  const [data, setData] = useState({});
  const navigate = useNavigate();

  // Fetch mall_id from local storage on component mount
  useEffect(() => {
    const mallId = localStorage.getItem("mall_ID");
    if (mallId) {
      setData((prevData) => ({
        ...prevData,
        mall_id: mallId,
      }));
    }
  }, []);

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountrySelection = (country) => {
    setSelectedCountry(country.name);
    setData({
      ...data,
      country_id: country.id,
    });
    dispatch(statesByCountry(country.id)); // Fetch states for the selected country
    setCountryOpen(false);
    setSelectedState(""); // Reset state and city when country is changed
    setSelectedCity("");
  };

  const toggleCountry = () => {
    setCountryOpen(!countryOpen);
  };

  const handleStateSelection = (state) => {
    setSelectedState(state.name);
    setData({
      ...data,
      state_id: state.id,
    });
    dispatch(citiesByStates(state.id)); // Fetch cities for the selected state
    setStateOpen(false);
    setSelectedCity(""); // Reset city when state is changed
  };

  const toggleState = () => {
    if (selectedCountry) {
      setStateOpen(!stateOpen);
    }
  };

  const handleCitySelection = (city) => {
    setSelectedCity(city.name);
    setData({
      ...data,
      city_id: city.id,
    });
    setCityOpen(false);
  };

  const toggleCity = () => {
    if (selectedState) {
      setCityOpen(!cityOpen);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { country_id, state_id, city_id, postal_code, address } = data;

    if (!country_id || !state_id || !city_id || !postal_code || !address) {
      toast.error("Please fill in all the fields.");
      return;
    } else {
      dispatch(mallAddressRegistration(data));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);
        navigate("/mall/bank-verification");
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }
  }, [loading, message, error, dispatch, navigate]);

  useEffect(() => {
    dispatch(countriesList());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(statesByCountry(selectedCountry.id));
    }
  }, [selectedCountry,dispatch]);

  return (
    <div className="w-full">
      <div className="flex justify-center w-full mt-14">
        <div className="flex flex-col gap-4 md:gap-16">
          <div className="flex justify-center">
            <img
              className="w-[300px] md:w-[372px]"
              src="/assets/icons/progress-2.svg"
              alt="Progress"
            />
          </div>
          <div className="p-4 flex gap-16">
            <div className="flex flex-col md:gap-4">
              <span className="font-semibold text-sm md:text-base">
                Please complete the form below to register your mall with us!
              </span>

              <span className="font-bold text-lg md:text-2xl flex flex-col mt-3">
                <span>Address Information</span>
              </span>

              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 w-full"
              >
                {/* country */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="country" className="font-semibold text-xs">
                    Country
                  </label>
                  <span className="flex items-center relative">
                    <input
                      type="text"
                      readOnly
                      name="country"
                      placeholder="Select Country"
                      value={selectedCountry}
                      className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none placeholder:text-xs cursor-pointer"
                      onClick={toggleCountry}
                    />
                    {countryOpen ? (
                      <RiArrowDropUpLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleCountry}
                      />
                    ) : (
                      <RiArrowDropDownLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleCountry}
                      />
                    )}
                  </span>
                  {countryOpen && (
                    <div className="flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2">
                      {!loading &&
                        country_list?.countries?.map((country) => (
                          <span
                            key={country.id}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleCountrySelection(country)}
                          >
                            {country.name}
                          </span>
                        ))}
                    </div>
                  )}
                </div>

                {/* state */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="state" className="font-semibold text-xs">
                    State
                  </label>
                  <span className="flex items-center relative">
                    <input
                      type="text"
                      readOnly
                      name="state"
                      placeholder="Select State"
                      value={selectedState}
                      className={`w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none placeholder:text-xs cursor-pointer ${
                        !selectedCountry ? "cursor-not-allowed" : ""
                      }`}
                      onClick={toggleState}
                    />
                    {stateOpen ? (
                      <RiArrowDropUpLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleState}
                      />
                    ) : (
                      <RiArrowDropDownLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleState}
                      />
                    )}
                  </span>
                  {stateOpen && (
                    <div className="flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2 h-24 overflow-y-auto">
                      {!loading &&
                        states_list?.states?.map((state) => (
                          <span
                            key={state.id}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleStateSelection(state)}
                          >
                            {state.name}
                          </span>
                        ))}
                    </div>
                  )}
                </div>

                {/* city */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="city" className="font-semibold text-xs">
                    City
                  </label>
                  <span className="flex items-center relative">
                    <input
                      type="text"
                      readOnly
                      name="city"
                      placeholder="Select City"
                      value={selectedCity}
                      className={`w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none placeholder:text-xs cursor-pointer ${
                        !selectedState ? "cursor-not-allowed" : ""
                      }`}
                      onClick={toggleCity}
                    />
                    {cityOpen ? (
                      <RiArrowDropUpLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleCity}
                      />
                    ) : (
                      <RiArrowDropDownLine
                        className="absolute right-4 !w-5 !h-5"
                        onClick={toggleCity}
                      />
                    )}
                  </span>
                  {cityOpen && (
                    <div className="flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2">
                      {!loading &&
                        cities_list?.cities?.map((city) => (
                          <span
                            key={city.id}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleCitySelection(city)}
                          >
                            {city.name}
                          </span>
                        ))}
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <label className="font-semibold text-xs">Postal Code</label>
                  <div className="relative w-full">
                    <input
                      className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-none placeholder:text-xs"
                      type="number"
                      name="postal_code"
                      placeholder="Enter Your Postal Code"
                      onChange={updateData}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <label htmlFor="address" className="font-semibold text-xs">
                    Full Address
                  </label>
                  <textarea
                    name="address"
                    id="address"
                    cols="30"
                    rows="10"
                    className="bg-[#F9FAFB] w-full h-[80px] md:h-[181px] px-4 p-2 md:p-3 rounded-[8px] outline-[#828282] font-normal text-sm resize-none"
                    placeholder="Enter Your Full Address..."
                    onChange={updateData}
                  ></textarea>
                </div>

                {/* submit button */}
                <button
                  disabled={loading}
                  type="submit"
                  className="bg-[#C19A6B] py-3 md:py-[14px] px-5 md:px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
                >
                  {loading ? "Loading ..." : "Next"}
                </button>
              </form>
            </div>

            <div className="hidden lg:block">
              <img src="/assets/icons/address.svg" alt="Address" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
