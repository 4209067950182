export const MALL_LOGIN = "mall/login";
export const MALL_FORGOT_PASSWORD = "mall/forgot-password";
export const MALL_PASSWORD_RESET = "mall/reset-password";
export const MALL_REGISTRATION = "mall/register";
export const MALL_REGISTRATION_OTP_VERIFICATION = "/mall/otp";
export const MALL_ADDRESS_REGISTRATION = "mall/register-address";
export const MALL_DETAILS_REGISTRATION = "mall/register-details";
export const MALL_STORE_REQUEST = "mall/requests";
export const MALL_REQUEST_GET_USER_BY_ID = "/mall/requests/";
export const MALL_ACCEPT_STORE = "/mall/store-request/";
export const MALL_REJECT_STORE = "/mall/reject-request/";
export const MALL_STORE_LIST = "mall/stores";
export const UPDATE_MALL_DETAILS = "mall/update-details";
export const UPDATE_MALL_LIST = "mall/list";
export const COUNTRY_LIST = "/countries";
export const STATES_BY_COUNTRY = "/countries/";
export const CITIES_BY_STATES = "/countries/state/";
