import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useState } from "react";
import EmailVerificationModal from "./EmailVerificationModal";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, mallProfileCreation } from "../../store/MallSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";

export default function ProfileCreationPage() {
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector((state) => state.mall);

  const [mallName, setMallName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerSupportNumber, setCustomerSupportNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeLogoFile, setStoreLogoFile] = useState(null);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [storeLogo, setStoreLogo] = useState(null);

  // form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      mallName === "" ||
      email === "" ||
      password === "" ||
      phoneNumber === "" ||
      customerSupportNumber === "" ||
      storeLogoFile === null
    ) {
      toast.error("Please fill all fields ...");
    } else {
      const formData = new FormData();
      formData.append("name", mallName);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("number", phoneNumber);
      formData.append("social_security_number", customerSupportNumber);
      formData.append("image", storeLogoFile);

      dispatch(mallProfileCreation(formData));
    }
  };

  useEffect(() => {
        /* eslint-disable eqeqeq */
    if (localStorage.getItem('step') == 1){
      document.getElementById("my_modal_3").showModal();
    }
  }, [])
  

  useEffect(() => {
    if (!loading) {
      if (message) {
        toast.success(message);

        document.getElementById("my_modal_3").showModal();
        localStorage.setItem("email", email);
      }
      if (error) {
        if (typeof error === "object" && Object.keys(error).length > 0) {
          const firstKey = Object.values(error)[0];
          if (Array.isArray(firstKey) && firstKey.length > 0) {
            toast.error(firstKey[0]);
          } else {
            toast.error("An unknown error occurred.");
          }
        } else if (typeof error === "string") {
          toast.error(error);
        } else {
          toast.error("An unknown error occurred.");
        }
      }
      dispatch(clearMessage());
    }
  }, [loading, message, error, dispatch, email]);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleStoreLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStoreLogoFile(file);
      const imageUrl = URL.createObjectURL(file);
      setStoreLogo(imageUrl);
    }
  };

  const handleStoreLogoDelete = () => {
    setStoreLogo(null);
    setStoreLogoFile(null);
  };

  return (
    <div className="w-full lg:px-10 xl:px-0">
      <div className="flex justify-center w-full mt-14">
        <div className="flex flex-col gap-4 md:gap-16">
          <div className=" flex justify-center">
            <img
              className="w-[300px] md:w-[372px]"
              src="/assets/icons/progress-1.svg"
              alt=""
            />
          </div>
          <div className=" p-4 flex gap-16">
            <div className="flex flex-col gap-4">
              <span className="font-semibold text-sm md:text-base">
                Please complete the form below to register your mall with us!
              </span>

              <span className="font-bold text-lg md:text-2xl flex flex-col mt-3">
                <span>Hello Mall Owner,</span>
                <span>How about we learn more about you?</span>
              </span>

              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-4 w-full"
              >
                {/* Mall name  */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Enter Your Mall Name
                  </label>
                  <input
                    type="text"
                    name="mall_name"
                    placeholder="Mall Name"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    value={mallName}
                    onChange={(e) => setMallName(e.target.value)}
                  />
                </div>

                {/* Mall adm number  */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Enter Your Number
                  </label>
                  <input
                    type="text"
                    name="mall_number"
                    placeholder="Mall Administration Number"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                {/*customer support number  */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Enter Your Customer Support Number
                  </label>
                  <input
                    type="text"
                    name="support_number"
                    placeholder="Customer Support Number"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                    value={customerSupportNumber}
                    onChange={(e) => setCustomerSupportNumber(e.target.value)}
                  />
                </div>

                {/* mall logo  */}

                <div className="flex flex-col gap-1  cursor-pointer">
                  <h2 className="text-xs font-semibold ">
                    {" "}
                    Mall Image/Icon/Logo
                  </h2>
                  <div className="h-24 w-24 bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative">
                    <input
                      id="frontFileInput"
                      type="file"
                      accept="image/*"
                      className="hidden "
                      onChange={handleStoreLogoUpload}
                    />
                    {storeLogo ? (
                      <div className="relative w-full h-full">
                        <img
                          src={storeLogo}
                          alt="Uploaded"
                          className="w-full h-full object-cover rounded"
                        />

                        <span
                          className="absolute top-0 right-0 text-white p-1 rounded-full bg-[#FAFAFA]"
                          onClick={handleStoreLogoDelete}
                        >
                          <img
                            src="assets/icons/close-modal.svg"
                            alt=""
                            className="w-3 h-3 !stroke-white  "
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        className="flex flex-col items-center justify-center w-full"
                        onClick={() =>
                          document.getElementById("frontFileInput").click()
                        }
                      >
                        <span className="text-[0.75rem] font-[400] text-[#dddcdc]">
                          +
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Email  */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                {/*Password */}
                <div className="flex flex-col gap-1">
                  <label htmlFor="" className="font-semibold text-xs">
                    Password
                  </label>
                  <span className="flex items-center relative">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      name="password"
                      placeholder="******"
                      className="w-full bg-[#FAFAFA] text-xs border border-[#EBF0ED] p-2 md:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordVisible ? (
                      <IoEyeOutline
                        className="absolute right-4 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <IoEyeOffOutline
                        className="absolute right-4 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </span>
                </div>

                {/* submit button  */}
                {!loading ? (
                  <>
                    <button
                      type="submit"
                      className="bg-[#C19A6B] py-3 md:py-[14px] px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
                    >
                      Next
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      disabled={loading}
                      className="bg-[#C19A6B] py-3 md:py-[14px] px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
                    >
                      Loading ...
                    </button>
                  </>
                )}
              </form>
            </div>

            <div className="hidden lg:block">
              <img src="/assets/icons/profile.svg" alt="" />
            </div>
          </div>
        </div>

        {/* modal  */}
        <dialog
          id="my_modal_3"
          className="modal p-8 !rounded-lg w-full md:w-[50%] mx-auto"
        >
          <div className="modal-box bg-white">
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button className=" absolute right-2 top-2 text-[21px] rounded-full shadow-md p-2 md:p-3">
                <img
                  className="w-3 h-3 md:w-5 md:h-5"
                  src="/assets/icons/close-modal.svg"
                  alt=""
                />
              </button>
            </form>
            <h3 className="font-semibold text-base md:text-lg">Verify Email</h3>
            <p className="py-2 md:py-4 text-xs md:text-sm">
              An email is sent to the address you entered. Enter the code in the
              email to verify your email address.
            </p>
            <EmailVerificationModal />
          </div>
        </dialog>
      </div>
    </div>
  );
}
