import React, { useState, useEffect } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearMessage, mallPasswordReset } from "../../store/MallSlice";
import { toast } from "react-toastify";

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error } = useSelector(
    (state) => state.mall
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");



  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  // for password visibility
  const togglePasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  // for confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "") {
      toast.error("Please fill all the fields");
      /* eslint-disable eqeqeq */
    } else if (confirmNewPassword != newPassword) {
      toast.error("Password And New Password Not Match");
    } else {
      dispatch(
        mallPasswordReset({
          email,
          password: newPassword,
          password_confirmation: confirmNewPassword,
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "Password successfully reset") {
        navigate("/");
        localStorage.clear();
      }
    }
    if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message, error, dispatch, navigate]);

  return (
    <div className="h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          New Password
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Enter your new password and confirm{" "}
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter New Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={newPasswordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {newPasswordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>

          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Confirm New Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 border border-[#EBF0ED] text-sm rounded-lg bg-[#FAFAFA] w-full  focus:outline-none focus:border-[#EBF0ED]"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
              {confirmPasswordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={toggleConfirmPasswordVisibility}
                />
              )}
            </span>
          </label>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-[#C19A6B] w-full rounded-lg py-3 h-12 md:p-4 mt-[2rem] text-white font-bold tracking-wider flex justify-center items-center"
          >
            {!loading ? (
              "Confirm"
            ) : (
              <span className="loading loading-spinner loading-md"></span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
