import ForgotPasswordPage from "../pages/SignIn-Pages/ForgotPasswordPage";
import LoginPag from "../pages/SignIn-Pages/LoginPag";
import NewPasswordPage from "../pages/SignIn-Pages/NewPasswordPage";

const authRoutes = [
  {
    index: true,
    element: <LoginPag />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/new-password",
    element: <NewPasswordPage />,
  },
];

export default authRoutes;
