import StoreListPage from "../pages/Store-Pages/StoreListPage";
import StoreRequestsPage from "../pages/Store-Pages/StoreRequestsPage";

const storeRoutes = [
  {
    path: "/mall/store-list",
    element: <StoreListPage />,
  },
  {
    path: "/mall/store-requests",
    element: <StoreRequestsPage />,
  },
];

export default storeRoutes;
