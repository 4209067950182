import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";


const Protected = () => {
  const step = localStorage.getItem("step");
  const { token} = useSelector((state) => state.mall);
      /* eslint-disable eqeqeq */
  if (!token || step == 3 || step == 1 || step == 2) {
    localStorage.clear();
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <main>
        <div className="flex w-full flex-col">

          <Outlet />
        </div>
      </main>
    </>
  );
};

export default Protected;
