import Chat from "../../components/Chat/Chat";
import MallDashboardLayout from "../../layout/MallDashboardLayout";

export default function ChatPage() {
  return (
    <MallDashboardLayout>
      <Chat />
    </MallDashboardLayout>
  );
}
