import React, { useEffect, useRef, useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import BreadCrums from "../../Common/BreadCrums";
import "./custom-input.css";
import { useDispatch, useSelector } from "react-redux";
import {
  citiesByStates,
  clearMessage,
  countriesList,
  mallDetail,
  statesByCountry,
  updateMallDetail,
} from "../../store/MallSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate()
  const {
    loading,
    data,
    message,
    error,
    country_list,
    states_list,
    cities_list,
  } = useSelector((state) => state.mall);

  const [initialBankInfo, setInitialBankInfo] = useState(null);
  const [initialProfileInfo, setInitialProfileInfo] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);
  const [profileInfo, setProfileInfo] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStates, setSelectedStates] = useState("");
  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);
  const [isStatesDropdownVisible, setStatesDropdownVisible] = useState(false);

  const dispatch = useDispatch();

  const inputRefcheque = useRef(null);
  const [imageCheque, setImageCheque] = useState(null);
  const [isChequeImageChanged, setIsChequeImageChanged] = useState(false);

  const inputReflegal1 = useRef(null);
  const [imageLegal1, setImageLegal1] = useState(null);
  const [isLegal1ImageChanged, setIsLegal1ImageChanged] = useState(false);

  const inputReflegal2 = useRef(null);
  const [imageLegal2, setImageLegal2] = useState(null);
  const [isLegal2ImageChanged, setIsLegal2ImageChanged] = useState(false);

  const inputReflegal3 = useRef(null);
  const [imageLegal3, setImageLegal3] = useState(null);
  const [isLegal3ImageChanged, setIsLegal3ImageChanged] = useState(false);

  const handleImageClickCheque = () => {
    inputRefcheque.current.click();
  };

  const handleImageClickLegal1 = () => {
    inputReflegal1.current.click();
  };

  const handleImageClickLegal2 = () => {
    inputReflegal2.current.click();
  };

  const handleImageClickLegal3 = () => {
    inputReflegal3.current.click();
  };

  const handleImageChangeCheque = (event) => {
    const file = event.target.files[0];
    setImageCheque(file);
    setIsChequeImageChanged(true);
  };

  const handleImageChangeLegal1 = (event) => {
    const file = event.target.files[0];
    setImageLegal1(file);
    setIsLegal1ImageChanged(true);
  };

  const handleImageChangeLegal2 = (event) => {
    const file = event.target.files[0];
    setImageLegal2(file);
    setIsLegal2ImageChanged(true);
  };

  const handleImageChangeLegal3 = (event) => {
    const file = event.target.files[0];
    setImageLegal3(file);
    setIsLegal3ImageChanged(true);
  };

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
    setStatesDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    setStatesDropdownVisible(false);
  };

  const toggleStatesDropdown = () => {
    setStatesDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    setCityDropdownVisible(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setCountryDropdownVisible(false);
    setProfileInfo({
      ...profileInfo,
      country_id: country.id,
    });
    dispatch(statesByCountry(country.id));
  };

  const selectState = (state) => {
    setSelectedStates(state.name);
    setStatesDropdownVisible(false);
    setProfileInfo({
      ...profileInfo,
      state_id: state.id,
    });
    dispatch(citiesByStates(state.id));
  };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setCityDropdownVisible(false);
    setProfileInfo({
      ...profileInfo,
      city_id: city.id,
    });
  };

  const handleUpdate = () => {
    console.log({ ...bankInfo, ...profileInfo });

    const formData = new FormData();
    formData.append("_method", "PUT");

    const updatedData = {};

    // Compare bankInfo and initialBankInfo to find changes
    for (const key in bankInfo) {
      if (bankInfo[key] !== initialBankInfo[key]) {
        updatedData[key] = bankInfo[key];
      }
    }

    // Compare profileInfo and initialProfileInfo to find changes
    for (const key in profileInfo) {
      if (profileInfo[key] != initialProfileInfo[key]) {
        updatedData[key] = profileInfo[key];
      }
    }

    // Append the updated data to form data
    for (const key in updatedData) {
      if (updatedData.hasOwnProperty(key)) {
        formData.append(key, updatedData[key]);
      }
    }

    // Append the new cheque image only if it has been changed
    if (isChequeImageChanged && imageCheque) {
      formData.append("cheque_copy_image", imageCheque);
    }

    // Append the new legal document images only if they have been changed
    if (isLegal1ImageChanged && imageLegal1) {
      formData.append("mall_legal_document1", imageLegal1);
    }
    if (isLegal2ImageChanged && imageLegal2) {
      formData.append("mall_legal_document2", imageLegal2);
    }
    if (isLegal3ImageChanged && imageLegal3) {
      formData.append("mall_legal_document3", imageLegal3);
    }

    dispatch(updateMallDetail(formData));
    dispatch(mallDetail());
  };

  useEffect(() => {
    dispatch(mallDetail());
  }, [dispatch]);

  useEffect(() => {
    if (!loading && data) {
      const mallBankInfo = data.mall_details;
      const mallProfileInfo = data.user;

      setBankInfo(mallBankInfo);
      setProfileInfo(mallProfileInfo);
      setInitialBankInfo(mallBankInfo);
      setInitialProfileInfo(mallProfileInfo);
    }

    const userData = data?.user;
    const country = country_list?.countries?.find(
      (country) => country.id == userData?.country_id
    );
    const state = states_list?.states?.find(
      (state) => state.id == userData?.state_id
    );
    const city = cities_list?.cities?.find(
      (city) => city.id == userData?.city_id
    );
    setSelectedCountry(country?.name || "");
    setSelectedStates(state?.name || "");
    setSelectedCity(city?.name || "");

    if (message) {
      toast.success(message);
      dispatch(clearMessage());
    }
  }, [
    data,
    loading,
    message,
    dispatch,
    country_list,
    states_list,
    cities_list,
  ]);

  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "Mall details updated successfully") {
        navigate("/mall/dashboard");
      }
    }
    if (error) {
      const firstError = Object.values(error)[0];
      toast.error(firstError[0]);
    }
    dispatch(clearMessage());
  }, [message, error, dispatch, navigate]);

  useEffect(() => {
    if (data?.user?.state_id || data?.user?.country_id) {
      dispatch(countriesList());
      dispatch(statesByCountry(data?.user?.country_id));
      dispatch(citiesByStates(data?.user?.state_id));
    }
  }, [data, dispatch]);

  return (
    <div className="xl:px-4 mb-3 flex flex-col gap-4 w-full">
      {/* head */}
      <div className="flex justify-between mb-2">
        <BreadCrums
          breadCrum={[
            {
              name: "Profile",
              path: "/mall/profile",
            },
          ]}
        />

        <button
          className="py-2 md:py-[14px] px-5 md:px-9  text-white bg-[#C19A6B] font-semibold text-xs rounded-lg"
          onClick={(e) => handleUpdate()}
        >
          Update
        </button>
      </div>

      {/* profile info  */}

      <div className="flex flex-col lg:flex-row justify-between gap-3 w-full">
        {/* mall info  */}
        <div className="flex flex-col gap-3 w-full lg:w-2/5">
          <div className=" border border-[#EBF0ED] rounded-lg p-3">
            <div className="flex items-center gap-2 mb-4">
              <div className="relative">
                <img
                  className="w-14 h-14"
                  src="/assets/images/user.png"
                  alt=""
                />
                <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                  <svg
                    width="10"
                    height="8"
                    viewBox="0 0 10 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h2 className="font-semibold text-base text-[#18120F]">
                  Mall Name
                </h2>
                <h2 className="text-[12px] font-semibold text-[#6B6B6B]">
                  Mall
                </h2>
              </div>
            </div>
            <div>
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  FULL NAME :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {profileInfo && profileInfo.name}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">Number :</p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {profileInfo && profileInfo.number}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  CUSTOMER SUPPORT NUMBER :
                </p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {profileInfo && profileInfo.social_security_number}
                </p>
              </div>
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">E-MAIL :</p>
                <p className="float-end text-[12px] font-semibold text-[#18120F]">
                  {profileInfo && profileInfo.email}
                </p>
              </div>
              <hr />
              <div className="flex justify-between my-2">
                <p className="text-[#6B6B6B] text-xs font-semibold">
                  LOCATION :
                </p>
                <p className="float-end text-xs font-semibold text-[#18120F]">
                  {profileInfo && profileInfo.social_security_number}
                </p>
              </div>
            </div>
          </div>

          {/* password  */}
        </div>

        {/* edit profile  */}
        <div className="w-full">
          <h1 className=" text-[#18120F] text-lg md:text-lg font-semibold">
            Edit Profile
          </h1>
          <div className="w-full border border-[#EBF0ED] rounded-lg p-3 uppercase">
            {/* Name Inputs */}
            <div className="flex flex-col md:flex-row items-center gap-2">
              <div className="flex w-full flex-col md:gap-2">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  Mall NAME
                </label>
                <input
                  className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                  type="text"
                  placeholder="Enter Your Name"
                  // value={data.name}
                  value={profileInfo && profileInfo.name}
                  onChange={(e) =>
                    setProfileInfo({ ...profileInfo, name: e.target.value })
                  }
                />
              </div>

              {/* email  */}
              <div className="flex w-full flex-col md:gap-2">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  EMAIL ADDRESS
                </label>
                <input
                  className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                  type="email"
                  placeholder="Enter Your Email"
                  // value={data.email}
                  value={profileInfo && profileInfo.email}
                  onChange={(e) =>
                    setProfileInfo({ ...profileInfo, email: e.target.value })
                  }
                />
              </div>
            </div>

            {/* Contact Inputs */}
            <div className="flex flex-col md:flex-row items-center gap-2 my-2">
              <div className="flex w-full flex-col md:gap-2 customInput">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  NUMBER
                </label>
                <input
                  className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                  type="number"
                  placeholder="+1-9846499950"
                  // value={data.number}
                  value={profileInfo && profileInfo.number}
                  onChange={(e) =>
                    setProfileInfo({ ...profileInfo, number: e.target.value })
                  }
                />
              </div>

              {/* customer support  */}
              <div className="flex w-full flex-col md:gap-2 customInput">
                <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                  Customer Support Number
                </label>
                <input
                  className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                  type="number"
                  placeholder="235232"
                  // value={data.social_security_number}
                  value={profileInfo && profileInfo.social_security_number}
                  onChange={(e) =>
                    setProfileInfo({
                      ...profileInfo,
                      social_security_number: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {/* Country, City, Postal Code Inputs */}
            <div className="relative flex flex-col md:flex-row gap-2 items-center ">
              {/* Country */}
              <div className="w-full">
                <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                  COUNTRY
                </label>
                <div className="relative w-full cursor-pointer">
                  <input
                    className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCountry}
                    readOnly
                    onClick={toggleCountryDropdown}
                    placeholder="Select Your Country"
                  />
                  {isCountryDropdownVisible ? (
                    <RiArrowDropUpLine
                      onClick={toggleCountryDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  ) : (
                    <RiArrowDropDownLine
                      onClick={toggleCountryDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  )}

                  {isCountryDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] h-[200px] overflow-hidden  rounded-lg mt-2 w-full">
                      {country_list?.countries?.map((country) => (
                        <div
                          key={country.id}
                          className="px-4 py-2 text-xs font-semibold hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCountry(country)}
                        >
                          {country.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* state */}
              <div className="w-full">
                <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                  State
                </label>
                <div className="relative w-full cursor-pointer">
                  <input
                    className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedStates}
                    readOnly
                    onClick={toggleStatesDropdown}
                    placeholder="Select Your City"
                  />
                  {isStatesDropdownVisible ? (
                    <RiArrowDropUpLine
                      onClick={toggleStatesDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  ) : (
                    <RiArrowDropDownLine
                      onClick={toggleStatesDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  )}

                  {isStatesDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                      {states_list?.states?.map((state) => (
                        <div
                          key={state.id}
                          className="px-4 py-2 text-xs font-semibold hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectState(state)}
                        >
                          {state.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* city */}
            <div className="flex flex-col md:flex-row w-full gap-2 my-2">
              <div className="w-full ">
                <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                  CITY
                </label>
                <div className="relative w-full cursor-pointer">
                  <input
                    className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                    type="text"
                    value={selectedCity}
                    readOnly
                    onClick={toggleCityDropdown}
                    placeholder="Select Your City"
                  />
                  {isCityDropdownVisible ? (
                    <RiArrowDropUpLine
                      onClick={toggleCityDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  ) : (
                    <RiArrowDropDownLine
                      onClick={toggleCityDropdown}
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                    />
                  )}

                  {isCityDropdownVisible && (
                    <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                      {cities_list?.cities?.map((city) => (
                        <div
                          key={city.id}
                          className="px-4 py-2 text-xs font-semibold hover:bg-gray-100 cursor-pointer"
                          onClick={() => selectCity(city)}
                        >
                          {city.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              {/* postal code  */}
              <div className="w-full">
                <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                  POSTAL CODE
                </label>
                <div className="relative w-full">
                  <input
                    className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED] outline-none"
                    type="number"
                    placeholder="Enter Your Postal Code"
                    // value={data.postal_code}
                    value={profileInfo && profileInfo.postal_code}
                    onChange={(e) =>
                      setProfileInfo({
                        ...profileInfo,
                        postal_code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col ga-2">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                Full Address
              </label>
              <textarea
                className="bg-[#FAFAFA]  text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-4 md:py-5 px-2 border border-[#EBF0ED] outline-none resize-none"
                type="text"
                placeholder="Full Address..."
                // value={data.address}
                value={profileInfo && profileInfo.address}
                onChange={(e) =>
                  setProfileInfo({ ...profileInfo, address: e.target.value })
                }
              >
                {" "}
              </textarea>
            </div>
          </div>
        </div>
      </div>

      {/* bank information  */}
      <div className="flex flex-col ">
        <h1 className=" text-[#18120F] text-base md:text-lg font-semibold">
          Bank Information
        </h1>
        <div className="w-full  border border-[#EBF0ED] rounded-lg p-3">
          {/* Name Inputs */}
          <div className="flex flex-col md:flex-row items-center gap-2 md:gap-7">
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                ACCOUNT TITLE
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Khan"
                // value={data.bank_account_title}
                name="bank_account_title"
                value={bankInfo && bankInfo.bank_account_title}
                onChange={(e) =>
                  setBankInfo({
                    ...bankInfo,
                    bank_account_title: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                ACCOUNT NUMBER
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="wizalot"
                name="bank_account_number"
                // value={data.bank_account_title}
                value={bankInfo && bankInfo.bank_account_number}
                onChange={(e) =>
                  setBankInfo({
                    ...bankInfo,
                    bank_account_number: e.target.value,
                  })
                }
              />
            </div>
          </div>

          {/* Contact Inputs */}
          <div className="flex flex-col md:flex-row items-center gap-2 md:gap-7 my-2">
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                BANK NAME
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Enter Your Country"
                // value={data.bank_account_title}
                value={bankInfo && bankInfo.bank_account_number}
                onChange={(e) =>
                  setBankInfo({
                    ...bankInfo,
                    bank_account_number: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                BANK BRANCH
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Select State"
                // value={data.bank_account_title}
                value={bankInfo && bankInfo.bank_branch_name}
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, bank_branch_name: e.target.value })
                }
              />
            </div>
          </div>
          {/* Contact Inputs */}
          <div className="flex flex-col md:flex-row items-center gap-2 md:gap-7 my-2">
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                BRANCH CODE
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Enter Your Phone City"
                // value={data.bank_account_title}
                value={bankInfo && bankInfo.bank_branch_code}
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, bank_branch_code: e.target.value })
                }
              />
            </div>
            <div className="flex w-full flex-col ">
              <label className="text-[#6B6B6B] text-xs ml-1 my-1 font-semibold">
                IBAN
              </label>
              <input
                className="bg-[#FAFAFA] text-xs font-normal text-[#6B6B6B] rounded-lg w-full py-2 md:py-3 px-2 border border-[#EBF0ED]   outline-none "
                type="text"
                placeholder="Enter Your Address"
                // value={data.Iban}
                value={bankInfo && bankInfo.Iban}
                onChange={(e) =>
                  setBankInfo({ ...bankInfo, Iban: e.target.value })
                }
              />
            </div>
          </div>
          {/* CHEQUE COPY */}
          <div className="py-1">
            <h2 className="text-[10px] md:text-xs mb-1 font-semibold text-[#6B6B6B]">
              UPLOAD CHEQUE COPY
            </h2>
            <div onClick={handleImageClickCheque} className="relative">
              {imageCheque ? (
                <img
                  className="w-24 h-20 rounded-lg"
                  src={URL.createObjectURL(imageCheque)}
                  alt=""
                />
              ) : (
                <img
                  className="w-24 h-20 rounded-lg"
                  src={bankInfo?.cheque_copy_image}
                  alt=""
                />
              )}
              <input
                ref={inputRefcheque}
                onChange={handleImageChangeCheque}
                type="file"
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/*Verification  details  */}
      <div className="flex flex-col border-2 border-[#EBF0ED] rounded-[11px] p-4 gap-4">
        <p className="font-semibold text-base md:text-lg">
          Verification Information
        </p>
        <div className=" w-full flex justify-between items-start gap-2 md:gap-16">
          {/* 1st  */}

          <div onClick={handleImageClickLegal1} className="relative">
            {imageLegal1 ? (
              <img
                className="w-24 h-20 rounded-lg"
                src={URL.createObjectURL(imageLegal1)}
                alt=""
              />
            ) : (
              <img
                className="w-24 h-20 rounded-lg"
                src={bankInfo?.mall_legal_document1}
                alt=""
              />
            )}
            <input
              ref={inputReflegal1}
              onChange={handleImageChangeLegal1}
              type="file"
              style={{ display: "none" }}
            />
          </div>
          {/* 2nd  */}

          <div onClick={handleImageClickLegal2} className="relative">
            {imageLegal2 ? (
              <img
                className="w-24 h-20 rounded-lg"
                src={URL.createObjectURL(imageLegal2)}
                alt=""
              />
            ) : (
              <img
                className="w-24 h-20 rounded-lg"
                src={bankInfo?.mall_legal_document2}
                alt=""
              />
            )}
            <input
              ref={inputReflegal2}
              onChange={handleImageChangeLegal2}
              type="file"
              style={{ display: "none" }}
            />
          </div>

          {/* 3rd */}
          <div onClick={handleImageClickLegal3} className="relative">
            {imageLegal3 ? (
              <img
                className="w-24 h-20 rounded-lg"
                src={URL.createObjectURL(imageLegal3)}
                alt=""
              />
            ) : (
              <img
                className="w-24 h-20 rounded-lg"
                src={bankInfo?.mall_legal_document3}
                alt=""
              />
            )}
            <input
              ref={inputReflegal3}
              onChange={handleImageChangeLegal3}
              type="file"
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="py-1">
          <h2 className="text-[8px] md:text-xs mb-1 font-semibold text-[#6B6B6B] uppercase">
            Upload Verification Document
          </h2>
          {/* <div className="relative w-[100px] h-[75px]">
            <div
              className="w-[100px] h-[75px] bg-[#FEFEFE] border-2 border-dashed border-[#E0E0E0] rounded-lg flex justify-center items-center cursor-pointer"
              onClick={handleDivClick1}
            >
              {uploadedImage1 ? (
                <img
                  src={uploadedImage1}
                  alt="Uploaded"
                  className="w-full h-full object-cover rounded-lg"
                />
              ) : (
                ""
              )}
            </div>

            {uploadedImage1 && (
              <button
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex justify-center items-center"
                onClick={handleDeleteImage1} // Delete the uploaded image
              >
                X
              </button>
            )}

            Hidden file input
            <input
              type="file"
              ref={fileInputRef1}
              onChange={handleFileChange1} // Handle file change
              style={{ display: "none" }} // Keep the input hidden
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
