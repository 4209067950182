import AddressCreation from "../pages/Mall-Creation-Pages/AddressCreationPage";
import BankVerificationPage from "../pages/Mall-Creation-Pages/BackVerificationPage";
import CreationCompletePage from "../pages/Mall-Creation-Pages/CreationCompletePage";
import ProfileCreationPage from "../pages/Mall-Creation-Pages/ProfileCreationPage";

const mallCreationRoutes = [
  {
    path: "/mall/profile-creation",
    element: <ProfileCreationPage />,
  },
  {
    path: "/mall/address-creation",
    element: <AddressCreation />,
  },
  {
    path: "/mall/bank-verification",
    element: <BankVerificationPage />,
  },
  {
    path: "/mall/creation-complete",
    element: <CreationCompletePage />,
  },
];

export default mallCreationRoutes;
