import DashboardPage from "../pages/Dashboard-Pages/DashboardPage";
import RegisteredProductsPage from "../pages/Dashboard-Pages/RegisteredProductsPage";
import RegisteredStoresPage from "../pages/Dashboard-Pages/RegisteredStoresPage";

const dashboardRoutes = [
  {
    path: "/mall/dashboard",
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "/mall/dashboard/registered-store", 
        element: <RegisteredStoresPage />,
      },
      {
        path: "/mall/dashboard/registered-products", 
        element: <RegisteredProductsPage />,
      },
    ],
  },
];

export default dashboardRoutes;
