import { useDispatch, useSelector } from "react-redux";
import RegisteredStores from "../../components/Dashboard/RegisteredStores";
import MallDashboardLayout from "../../layout/MallDashboardLayout";
import { storeList } from "../../store/MallSlice";
import { useEffect } from "react";

export default function RegisteredStoresPage() {

  const dispatch = useDispatch();
  const {loading, data } = useSelector(
    (state) => state.mall
  );

  useEffect(() => {
    dispatch(storeList());
  }, [])

  return (
    <MallDashboardLayout>
      <RegisteredStores loading ={loading} stores = {data?.data?.data} />
    </MallDashboardLayout>
  );
}
