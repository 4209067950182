import { useRef, useState } from "react";
import "./email-verification.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, mallOtpVerification } from "../../store/MallSlice";
import { useEffect } from "react";

export default function EmailVerificationModal() {
  const dispatch = useDispatch();
  const { loading, message,error } = useSelector((state) => state.mall);

  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const value = event.target.value;

    if (value.length > 1) return;

    const newInputs = [...inputs];
    newInputs[index] = value;

    setInputs(newInputs);

    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("user data...", inputs);

    const OTP = inputs.join("");
    const EMAIL = localStorage.getItem("email");

    if (OTP === "") {
      toast.error("Please Enter 6 Digits OTP");
    } else {
      dispatch(mallOtpVerification({ otp: OTP, email: EMAIL }))
    }
  };

  useEffect(() => {

    console.log(message);
    if (message === "OTP has been verified") {
      toast.success(message);
      navigate("/mall/address-creation");
    }
    if (error) {
      toast.error(message);
    }

    dispatch(clearMessage());

  }, [message,error, dispatch, navigate]);

  return (
    <div className="flex justify-center mt-3 ">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-8 items-center"
      >
        <div className="flex items-center gap-4">
          {inputs.map((value, index) => (
            <span key={index} className="email">
              <input
                type="number"
                className="w-7 md:w-10 outline-none border-b-2 border-black pb-2 text-center text-black text-lg font-semibold "
                value={value}
                maxLength={1}
                onChange={(event) => handleChange(index, event)}
                ref={(input) => (inputRefs.current[index] = input)}
              />
            </span>
          ))}
        </div>

        {/* buttons  */}
        <div className="flex gap-4">
          <Link
          onClick={() => document.getElementById("my_modal_3").close()}
          to="/mall/profile-creation"
           className="py-[7px] md:py-[10px] px-5 md:px-9 text-nowrap rounded-lg border border-[#C19A6B] text-[#C19A6B] text-xs md:text-sm font-semibold tracking-wider">
            Change Email
          </Link>
          {!loading ? (
            <>
              <button
                type="submit"
                className="py-[7px] md:py-[10px] px-5 md:px-9 text-nowrap rounded-lg bg-[#C19A6B]  text-white text-xs md:text-sm font-semibold tracking-wider"
              >
                Verify
              </button>
            </>
          ) : (
            <>
              <button
                disabled={loading}
                type="submit"
                className="py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg bg-[#C19A6B]  text-white text-xs md:text-sm font-semibold tracking-wider"
              >
                Verifying ...
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
}
