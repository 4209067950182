import ProfilePage from "../pages/Profile-Page/ProfilePage";

const profileRoutes = [
  {
    path: "/mall/profile",
    element: <ProfilePage />,
  },
];

export default profileRoutes;
