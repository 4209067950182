import { useDispatch, useSelector } from "react-redux";
import RegisteredProducts from "../../components/Dashboard/RegisteredProducts";
import MallDashboardLayout from "../../layout/MallDashboardLayout";
import { useEffect } from "react";
import { storeProducts } from "../../store/MallSlice";

export default function RegisteredProductsPage() {

  const dispatch = useDispatch();
  const {loading, data } = useSelector(
    (state) => state.mall
  );

  useEffect(() => {

    dispatch(storeProducts());

  }, [])
  

  
  return (
    <MallDashboardLayout>
      <RegisteredProducts loading ={loading} regProduct = {data?.products_registered} />
    </MallDashboardLayout>
  );
}
