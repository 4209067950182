import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import axios from "../functions/axios";
import {
  CITIES_BY_STATES,
  COUNTRY_LIST,
    MALL_ACCEPT_STORE,
    MALL_ADDRESS_REGISTRATION,
    MALL_DETAILS_REGISTRATION,
    MALL_FORGOT_PASSWORD,
    MALL_LOGIN,
    MALL_PASSWORD_RESET,
    MALL_REGISTRATION,
    MALL_REGISTRATION_OTP_VERIFICATION,
    MALL_REJECT_STORE,
    MALL_REQUEST_GET_USER_BY_ID,
    MALL_STORE_LIST,
    MALL_STORE_REQUEST,
    STATES_BY_COUNTRY,
    UPDATE_MALL_DETAILS,
    UPDATE_MALL_LIST
} from "./URLConstant";


const initialState = {
    data: [],
    country_list: [],
    states_list: [],
    cities_list: [],
    storeData:[],
    loading: false,
    message: null,
    error: null,
    status_code: null,
    signout: false,
    token: localStorage.getItem('token') || null,
    role: localStorage.getItem('cookies') || null,

};
//mall login
export const loginUser = createAsyncThunk("admin/loginUser", async (user, {
  rejectWithValue
}) => {
  try {
    const res = await axios.post(MALL_LOGIN, user);
    return { data: res.data, status: res.status };
  } catch (err) {
    return rejectWithValue({
      data: err.response.data,
      status: err.response.status
    });
  }
});
// mall forgot password 
export const mallForgotPassword = createAsyncThunk("mallForgotPassword", async (email, {
  rejectWithValue
}) => {
  try {
      const res = await axios.post(MALL_FORGOT_PASSWORD, email);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});
// mall password reset
export const mallPasswordReset = createAsyncThunk("mallPasswordReset", async (password_detail, {
  rejectWithValue
}) => {
  try {
      const res = await axios.post(MALL_PASSWORD_RESET, password_detail);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});
// Async thunk for mall registration
export const mallProfileCreation = createAsyncThunk("mallProfileCreation", async (user, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(MALL_REGISTRATION, user);
        return { data: res.data, status: res.status };
    } catch (err) {
      return rejectWithValue({
        data: err.response.data,
        status: err.response.status
      });
    }
});
// Async thunk for mall registration otp verification
export const mallOtpVerification = createAsyncThunk("mallOtpVerification", async (otp, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(MALL_REGISTRATION_OTP_VERIFICATION, otp);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// Async thunk for mall registration address registration
export const mallAddressRegistration = createAsyncThunk("mallAddressRegistration", async (address_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(MALL_ADDRESS_REGISTRATION, address_info);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// Async thunk for mall details registration
export const mallDetailsRegistration = createAsyncThunk("mallDetailsRegistration", async (details_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(MALL_DETAILS_REGISTRATION, details_info);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const storeRequest = createAsyncThunk("storeRequest", async (details_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(MALL_STORE_REQUEST);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const storeList = createAsyncThunk("storeList", async (details_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(MALL_STORE_LIST);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const mallDetail = createAsyncThunk("mallDetail", async (details_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(UPDATE_MALL_LIST);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const getStoreById = createAsyncThunk("getStoreById", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(MALL_REQUEST_GET_USER_BY_ID+id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const acceptStore = createAsyncThunk("acceptStores", async (arg, {
    rejectWithValue
}) => {
    try {
        const res = await axios.put(`${MALL_ACCEPT_STORE}${arg.id}?status=${arg.status}`);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const rejectStore = createAsyncThunk("rejectStore", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.put(MALL_REJECT_STORE+id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const updateMallDetail = createAsyncThunk("updateMallDetail", async (data, {
    rejectWithValue
}) => {
 
    try {
        const res = await axios.post(UPDATE_MALL_DETAILS,data);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
export const countriesList = createAsyncThunk("/countriesList", async (id, {
  rejectWithValue
}) => {
  try {
      const res = await axios.get(COUNTRY_LIST);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});
export const statesByCountry = createAsyncThunk("/statesByCountry", async (id, {
  rejectWithValue
}) => {
  try {
      const res = await axios.get(STATES_BY_COUNTRY+id);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});
export const citiesByStates = createAsyncThunk("/citiesByStates", async (id, {
  rejectWithValue
}) => {
  try {
      const res = await axios.get(CITIES_BY_STATES+id);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});
export const storeProducts = createAsyncThunk("/storeProducts", async (id, {
  rejectWithValue
}) => {
  try {
      const res = await axios.get(`mall/products`);
      return res.data;
  } catch (err) {
      return rejectWithValue(err.response.data);
  }
});

const MallSlice = createSlice({
    name: "mall",
    initialState,
    reducers: {
        clearMessage: (state) => {
            state.message = null;
            state.error = null;
            state.status_code = null
        },
       
          resetSignout: (state) => {
            state.signout = false;
          
          },
    },
    extraReducers: (builder) => {

        builder
            .addCase(mallProfileCreation.pending, (state) => {
                state.loading = true;
            })
            .addCase(mallProfileCreation.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                    state.message = payload.data?.message;
                    state.data = payload.data;

            })
            .addCase(mallProfileCreation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.data.errors
                console.log("reg",action.payload)
            });

        // otp build cases
        builder
            .addCase(mallOtpVerification.pending, (state) => {
                state.loading = true;
            })
            .addCase(mallOtpVerification.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                    state.message = payload.message;
                    state.data = payload;

            })
            .addCase(mallOtpVerification.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
                console.log(action)
            });

        // mall address registration
        builder
            .addCase(mallAddressRegistration.pending, (state) => {
                state.loading = true;
            })
            .addCase(mallAddressRegistration.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (!payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    console.log(payload)
                } else {
                    console.log("payload", payload)
                }
            })
            .addCase(mallAddressRegistration.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error || "Mall address registration failed";
                console.log("action", action)
                console.log(action.payload.errors)
            });

        // mall details registration
        builder
            .addCase(mallDetailsRegistration.pending, (state) => {
                state.loading = true;
            })
            .addCase(mallDetailsRegistration.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (!payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    console.log(payload)
                } else {
                    console.log("payload", payload)
                }
            })
            .addCase(mallDetailsRegistration.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.error || "Mall Details registration failed";
                console.log("action", action)
                console.log(action.payload.errors)
            });

        builder
            .addCase(loginUser.pending, (state) => {
              state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;

                state.data = payload.user;
                // state.role = payload.user.role;
                state.token = payload?.data?.mall_token;
                state.status_code = payload?.data?.status
                state.message = payload?.data?.message;
                localStorage.setItem("token", payload?.data?.temp_token?payload?.data?.temp_token: payload?.data?.mall_token); // Store the token in localStorage
                // localStorage.setItem("cookies", payload.user.role); // Store the token in localStorage
                localStorage.setItem("step", payload?.data?.step)
                console.log(payload)

            })
            .addCase(loginUser.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload.data.message
              console.log(action.payload)
              state.status_code = action.payload.status
              const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
            });
            // mall forgot password
            builder
            .addCase(mallForgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(mallForgotPassword.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;

                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;

            })
            .addCase(mallForgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)
            });
                // mall password reset otp
                builder
                .addCase(mallPasswordReset.pending, (state) => {
                    state.loading = true;
                })
                .addCase(mallPasswordReset.fulfilled, (state, {
                    payload
                }) => {
                    state.loading = false;
                        state.message = payload.message;
                        state.data = payload;
                        state.status = payload.success;

                })
                .addCase(mallPasswordReset.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload.message

                });
        
         builder
            .addCase(storeRequest.pending, (state) => {
              state.loading = true;
            })
            .addCase(storeRequest.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;
              if (!payload.success) {
                  state.error = payload.message;
                  console.log(payload)
                  state.data=payload
                  state.storeData = payload.vendor_details
              } 
            })
            .addCase(storeRequest.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });
            builder
            .addCase(getStoreById.pending, (state) => {
              state.loading = true;
            })
            .addCase(getStoreById.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;
              if (!payload.success) {
                  state.error = payload.message;
                  state.storeData=payload.vendor_details
              } 
            })
            .addCase(getStoreById.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });
            builder
            .addCase(acceptStore.pending, (state) => {
              state.loading = true;
            })
            .addCase(acceptStore.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;

                  state.message = payload.message;
                  console.log(payload.message,"message----")
                //   state.storeData=null
              
            })
            .addCase(acceptStore.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });
            builder
            .addCase(rejectStore.pending, (state) => {
              state.loading = true;
            })
            .addCase(rejectStore.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;
              if (!payload.success) {
                  state.message = payload.message;
                  console.log(payload.message,"message----")
                //   state.storeData=null
              } 
            })
            .addCase(rejectStore.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });
            builder
            .addCase(updateMallDetail.pending, (state) => {
              state.loading = true;
            })
            .addCase(updateMallDetail.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;

                  state.message = payload.message;
                  console.log(payload.message,"message----")
                //   state.storeData=null

            })
            .addCase(updateMallDetail.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.errors;
              console.log(action)
            });
        
            builder
            .addCase(mallDetail.pending, (state) => {
              state.loading = true;
            })
            .addCase(mallDetail.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;
              
              if (payload.status) {
                  state.error = payload.message;
                  console.log(payload)
                  state.data = payload
              } 
            })
            .addCase(mallDetail.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });
            builder
            .addCase(storeList.pending, (state) => {
              state.loading = true;
            })
            .addCase(storeList.fulfilled, (state, {
              payload
            }) => {
              state.loading = false;

                  state.error = payload.message;
                  console.log(payload)
                  state.data=payload

            })
            .addCase(storeList.rejected, (state, action) => {
             const error = action.payload?.error;
       
              if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
              state.loading = false;
              state.error = action.payload.message || "Login failed";
              console.log(action)
            });

            // countries
            builder.addCase(countriesList.pending, (state) => {
              state.loading = true;
            });
            builder.addCase(countriesList.fulfilled, (state, action) => {
              state.loading = false;
              state.country_list = action.payload;
              console.log(action)
            });
            builder.addCase(countriesList.rejected, (state, action) => {
              const error=action?.payload?.error
              if(error && error.includes('Unauthorized')){
                state.signout=true
              }
              
              state.loading = false;
              state.error = action?.payload?.errors;
              console.log(action)
            });
            // states by country
            builder.addCase(statesByCountry.pending, (state) => {
              state.loading = true;
            });
            builder.addCase(statesByCountry.fulfilled, (state, action) => {
              state.loading = false;
              state.states_list = action.payload;
              console.log(action)
            });
            builder.addCase(statesByCountry.rejected, (state, action) => {
              const error=action?.payload?.error
              if(error && error.includes('Unauthorized')){
                state.signout=true
              }
              
              state.loading = false;
              state.error = action?.payload?.errors;
              console.log(action)
            });
            // cities by states
            builder.addCase(citiesByStates.pending, (state) => {
              state.loading = true;
            });
            builder.addCase(citiesByStates.fulfilled, (state, action) => {
              state.loading = false;
              state.cities_list = action.payload;
              console.log(action)
            });
            builder.addCase(citiesByStates.rejected, (state, action) => {
              const error=action?.payload?.error
              if(error && error.includes('Unauthorized')){
                state.signout=true
              }
              
              state.loading = false;
              state.error = action?.payload?.errors;
              console.log(action)
            });

            builder.addCase(storeProducts.pending, (state) => {
              state.loading = true;
            });
            builder.addCase(storeProducts.fulfilled, (state, action) => {
              state.loading = false;
              state.data = action.payload;
            });
            builder.addCase(storeProducts.rejected, (state, action) => {
              state.loading = false;
            });

    },
    
    })
export const {
    clearMessage,
    resetSignout
} = MallSlice.actions;
export default MallSlice.reducer;