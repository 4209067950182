import Protected from "../pages/Protected";
import Root from "../pages/Root";
import authRoutes from "./authRoutes";
import chatRoutes from "./chatRoutes";
import dashboardRoutes from "./dashboardRoutes";
import mallCreationRoutes from "./mallCreationRoutes";
import profileRoutes from "./profileRoutes";
import storeRoutes from "./storeRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      ...mallCreationRoutes,
      {
        path: "/mall",
        element: <Protected />,
        children: [
          ...dashboardRoutes,
          ...profileRoutes,
          ...storeRoutes,
          ...chatRoutes,
        ],
      },
    ],
  },
];

export default mainRoutes;
