import React from "react";
import MallDashboardLayout from "../../layout/MallDashboardLayout";
import Dashboard from "../../components/Dashboard/Dashboard";

export default function DashboardPage() {
  return (
    <MallDashboardLayout>
      <Dashboard />
    </MallDashboardLayout>
  );
}
