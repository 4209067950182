import StoreList from "../../components/Store/StoreList";
import MallDashboardLayout from "../../layout/MallDashboardLayout";

export default function StoreListPage() {
  return (
    <MallDashboardLayout>
      <StoreList />
    </MallDashboardLayout>
  );
}
