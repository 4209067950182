import { MdKeyboardArrowRight } from "react-icons/md";

import { Link } from "react-router-dom";
import MallEarningCharts from "../Graph/MallEarningChart";
import BreadCrums from "../../Common/BreadCrums";
export default function Dashboard() {
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Mall Dashboard",
          },
        ]}
      />
      {/* graph  */}
      <div className="w-full flex flex-col md:flex-row gap-4 md:gap-4 ">
        {/* graph  */}
        <div className="w-full flex flex-col gap-3">
          <MallEarningCharts />
        </div>

        {/* listed product and categories  */}
        <div className="flex flex-row md:flex-col gap-4 justify-between md:justify-start">
          <div className="w-full flex flex-col gap-4 xl:gap-10 text-left p-4 lg:p-7 shadow-md rounded-lg">
            <span className="font-semibold text-sm xl:text-xl text-nowrap">
              Stores Registered
            </span>
            <div className="flex items-center justify-between gap-10">
              <span className="font-bold text-3xl xl:text-[76px]  leading-[1.6rem] xl:leading-[4rem]">
                23
              </span>
              <Link
                to="/mall/dashboard/registered-store"
                className="p-2 xl:p-3 bg-[#C19A6B] text-white rounded-[9.5px] mb-1"
              >
                <MdKeyboardArrowRight className="w-5 h-5" />
              </Link>
            </div>
          </div>

          <div className="w-full flex flex-col gap-4 xl:gap-10 text-left p-4 lg:p-7 shadow-md rounded-lg">
            <span className="font-semibold text-sm xl:text-xl text-nowrap">
              Products Registered
            </span>
            <div className="flex items-center justify-between gap-10">
              <span className="font-bold text-3xl xl:text-[76px] leading-[1.6rem] xl:leading-[4rem]">
                13
              </span>
              <Link
                to="/mall/dashboard/registered-products"
                className="p-2 xl:p-3 bg-[#C19A6B] text-white rounded-[9.5px] "
              >
                <MdKeyboardArrowRight className="w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* tables  */}

      <div className="flex flex-col md:flex-row gap-[25px]">
        {/* 1st card */}
        <div className="w-full flex flex-col gap-4 px-4 xl:px-8 py-4 shadow-md rounded-lg">
          <p className="font-semibold text-xl">Top Stores</p>
          <div className="w-full">
            <thead>
              <div className="flex w-full justify-between items-center   gap-4  p-1  font-bold text-xs md:text-base text-black">
                <span className="text-left w-2/5">Store</span>
                <span className="w-28 text-left text-nowrap">
                  Products Sold
                </span>
                <span className="w-16 text-left">Profit</span>
              </div>
            </thead>
            {/* body  */}
            <tbody className="w-full">
              <div className="flex w-full justify-between items-center p-1 gap-2 font-semibold text-[10px] md:text-sm">
                <td className=" w-2/5 flex gap-2 md:gap-6 items-center text-left">
                  <span>
                    <img
                      src="/assets/images/living-room.png"
                      alt=""
                      className="w-11 h-11 rounded-lg"
                    />
                  </span>
                  <p className="font-semibold text-[10px] md:text-base">Name</p>
                </td>
                <td className="w-28 text-left">10</td>
                <td className="w-16 text-left">$430</td>
              </div>
            </tbody>
          </div>
        </div>

        {/* 2nd card */}
        <div className="w-full flex flex-col gap-4 px-4 md:px-8 py-4 shadow-md rounded-lg">
          <p className="font-semibold text-xl">Top Products</p>
          <div className="w-full">
            <thead>
              <div className="flex w-full justify-between items-center   gap-4  p-1  font-bold text-xs md:text-base text-black">
                <span className="text-left w-3/5">Product</span>
                <span className="w-14 text-left">Sold</span>
                <span className="w-14 text-left">Price</span>
                <span className="w-14 text-left">Profit</span>
              </div>
            </thead>
            {/* body  */}
            <tbody className="w-full">
              <div className="flex w-full justify-between items-center p-1 gap-2 font-semibold text-[10px] md:text-sm">
                <td className=" w-3/5 flex gap-2 md:gap-6 items-center text-left">
                  <span>
                    <img
                      src="/assets/images/living-room.png"
                      alt=""
                      className="w-11 h-11 rounded-lg"
                    />
                  </span>
                  <span className="flex flex-col ">
                    <p className="font-semibold text-[10px] md:text-sm">Name</p>
                    <p className="font-semibold text-[10px] md:text-base text-[#6B6B6B] text-nowrap">
                      Category: Name
                    </p>
                  </span>
                </td>
                <td className="w-14 text-left">34</td>
                <td className="w-14 text-left">$321</td>
                <td className="w-14 text-left">$211</td>
              </div>
            </tbody>
          </div>
        </div>
      </div>
    </div>
  );
}
