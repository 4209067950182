import Profile from "../../components/Profile/Profile";
import MallDashboardLayout from "../../layout/MallDashboardLayout";

export default function ProfilePage() {
  return (
    <MallDashboardLayout>
      <Profile />
    </MallDashboardLayout>
  );
}
