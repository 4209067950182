import { Link } from "react-router-dom";
import RequestModal from "./RequestModal";
import BreadCrums from "../../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { storeRequest, getStoreById, clearMessage } from "../../store/MallSlice";
// import { imagePath } from "../../functions/URLString";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";

export default function StoreRequests() {
  const dispatch = useDispatch();
  const { loading, message, data } = useSelector(
    (state) => state.mall
  );
  const [userId, setUserId] = useState(null);
  const [mallID, setMallId] = useState(null);
  useEffect(() => {
    dispatch(storeRequest());
  }, [dispatch]);
  useEffect(() => {
    if (userId) dispatch(getStoreById(userId));

  }, [userId, dispatch]);
  useEffect(() => {
    if (message) {
      toast.success(message)
      dispatch(storeRequest())
    }

    dispatch(clearMessage())
  }, [message, dispatch]);



  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Store Requests",
            path: "/mall/store-requests",
          },
        ]}
      />

      {/* search */}
      <div className="flex justify-between gap-5">
        <div className="relative flex items-center w-full ">
          <img
            src="/assets/icons/search.svg"
            alt=""
            className="absolute left-3"
          />

          <input
            className="w-full p-2 md:p-3 placeholder:px-5 rounded-lg bg-white border-2 border-[#EBF0ED] focus:outline-none text-[#6B6B6B} font-[500px] text-[14px]"
            type="search"
            placeholder="Search..."
          />
        </div>
      </div>

      {/* table */}

      <div className="flex justify-center border-gray-500 overflow-auto">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[568px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="text-left w-[20%]">Image</span>
              <span className="text-left w-[20%]">Shop Name</span>

              <span className="text-left w-[20%]">Email</span>

              <span className="  text-left w-[20%]">Vendor Name</span>

              <span className=" text-left w-[20%] ">ACTION</span>
            </div>

            {/* Table Data Row */}

            {loading ? (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            ) : data?.vendor_details?.length > 0 ? (
              data?.vendor_details?.map((state) => (
                  <div
                    // key={item.id}
                    className="flex text-[10px] md:text-sm items-center justify-between font-semibold text-[#18120F] p-2 md:p-4 border-b"
                  >
                    <div className="w-[20%] text-left">
                      <img
                        src={state.shop_picture}
                        alt=""
                        className="w-7 h-7 md:w-10 md:h-10 rounded-full"
                      />
                    </div>
                    <span className="w-[20%] text-left ">
                      {state.store_name}
                    </span>

                    <span className="w-[20%] text-left">{state.email}</span>

                    <span className="w-[20%] text-left ">{state.name}</span>

                    <div className="w-[20%] text-left">
                      <Link
                        onClick={() => {
                          document.getElementById("my_modal_3").showModal();
                          setUserId(state.user_id);
                          setMallId(state.user_id);
                        }}
                      >
                        <img
                          src="/assets/icons/view.svg"
                          alt=""
                          className="w-7 h-7 md:w-9 md:h-9"
                        />
                      </Link>
                    </div>
                  </div>
              ))
            ) : (
              <div className="text-center font-bold tracking-wide">
                No Store Request Found
              </div>
            )}
          </div>
        </div>
      </div>

      {/* modal  */}
      <dialog id="my_modal_3" className="modal p-8 !rounded-lg w-full">
        <div className="modal-box  flex flex-col gap-8 bg-white max-w-full ">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute right-8 top-8 text-[21px]">
              <img src="/assets/icons/close-modal.svg" alt="" />
            </button>
          </form>
          <RequestModal mallId={mallID} />
        </div>
      </dialog>
    </div>
  );
}
